<template>
  <v-container>
    <!-- <v-toolbar
      color="primary"
      dark
    >
      <span>Customers</span>
      <v-spacer></v-spacer>
      <v-icon>mdi-account</v-icon>
    </v-toolbar> -->
    <v-data-table
      :items="custList"
      :headers="headers"
      :loading="custGetting"
      :search="search"
    >
      <template v-slot:top>
        <v-row>
            <v-col cols="12" lg="3" md="3">
              <v-text-field
                label="search"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.company }}</td>
          <td>{{ item.customer_category_id }}</td>
          <td>
            <tooltip-btn
              color="yellow"
              fab
              btn-class="mr-1"
              tooltip="Edit"
              @click="$router.push({ name: 'customers.edit', params: { customer: item.id } })"
              small
            >
              <v-icon>mdi-pencil</v-icon>
            </tooltip-btn>
            <tooltip-btn
              v-if="!item.detail"
              btn-class="mr-1"
              color="red"
              fab
              tooltip="Detail"
              @click="$router.push({ name: 'customers.detail.create', params: { customer: item.id } })"
              small
            >
              <v-icon>mdi-account-box-multiple</v-icon>
            </tooltip-btn>
            <tooltip-btn
              v-else
              color="green"
              btn-class="mr-1"
              fab
              tooltip="Detail"
              @click="$router.push({ name: 'customers.detail.edit', params: { customer: item.id } })"
              small
            >
              <v-icon>mdi-account-box-multiple</v-icon>
            </tooltip-btn>
            <tooltip-btn
              color="purple"
              fab
              tooltip="Forecast"
              @click="$router.push({ name: 'customers.forecasts.table', params: { customerId: item.id } })"
              small
            >
              <v-icon>mdi-poll</v-icon>
            </tooltip-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import customerRepository from '@/repositories/customer.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Company', value: 'company' },
    { text: 'Company Category', value: 'customer_category_id' },
    { text: 'Config', sortable: false },
  ],
  search: null,
})

const getDataDelay = searchDelay()

export default {
  name: 'TableCustomer',
  components: {
    tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  created () {
    this.getCustomers()
    this.websocketEvent()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('cust'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  methods: {
    getCustomers () {
      if (this.custGetting) return
      this.custGetting = true
      getDataDelay(() => {
        customerRepository.index()
          .then(({ data }) => { this.custList = data })
          .catch(e => { this.custGetErrors = errorHandler(e) })
          .then(() => { this.custGetting = false })
      })
    },
    websocketEvent () {
      this.echo.channel('database.updated')
        .listen('CustomerUpdatedEvent', c => { this.getCustomers() })
    },
  },
}
</script>
